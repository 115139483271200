import { api } from ":mods";
import { ENDPOINTS } from "../const";

export async function getJobs() {

      return api
		.get(ENDPOINTS.JOBS_LIST)
		.then(async (res) => {
                  const jobsList  = res.data;
			if (!jobsList) return Promise.reject();
			return Promise.resolve(jobsList);
		})
		.catch((err) => {})

}